import { useService } from '@Client/runner.hooks/useService';
import IFeatureDetail from '@Shared.Angular/@types/core/contracts/config/featureDetail';
import { Guid } from '@Shared.Angular/@types/guid';
import React, { useEffect, useState } from 'react';
import Feature from '../Feature/Feature';

type Props = {
  businessId: Guid;
  onFeatureSelect: (featureName: string) => void;
};

interface IBusinessFeatureDetail extends IFeatureDetail {
  key: string;
}

const FeatureList = (props: Props) => {
  const { businessId, onFeatureSelect } = props;
  const [features, setFeatures] = useState<IBusinessFeatureDetail[]>([]);
  const businessService = useService<BusinessApiService>('businessApiService');

  useEffect(() => {
    if (!businessId) {
      setFeatures([]);
      return;
    }
    businessService.getFeatures(businessId).then((newFeatures) => {
      const businessFeatures = newFeatures as IBusinessFeatureDetail[];
      businessFeatures.sort((a, b) => a.name.localeCompare(b.name));
      businessFeatures.forEach((f) => (f.key = businessId + f.name));
      setFeatures(businessFeatures);
    });
  }, [businessId]);

  return (
    <div id="feature-list">
      {features.map((f) => (
        <Feature
          businessId={businessId}
          feature={f}
          key={f.key}
          onFeatureSelect={onFeatureSelect}
        ></Feature>
      ))}
    </div>
  );
};

export default FeatureList;
