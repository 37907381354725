'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, {
  ICompileService,
  IHttpService,
  IQService,
  IRootScopeService,
  IWindowService
} from 'angular';

angular
  .module('flowingly.runner.redirect')
  .service('redirectService', [
    'jwtHelper',
    'store',
    '$window',
    'APP_CONFIG',
    '$compile',
    '$rootScope',
    '$http',
    'flowinglyConstants',
    '$q',
    'sessionService',
    'tempModelerUrlService',
    'appInsightsService',
    redirectService
  ]);

function redirectService(
  jwtHelper: angular.jwt.IJwtHelper,
  localStorageSvc: angular.a0.storage.IStoreService,
  $window: IWindowService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  $compile: ICompileService,
  $rootScope: IRootScopeService,
  $http: IHttpService,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  $q: IQService,
  sessionService: SharedAngular.SessionService,
  tempModelerUrlService: SharedAngular.TempModelerUrlService
) {
  let blockerElement;
  function setPendingRequestAsJwt(jwtToken) {
    /**
     * the runner app has code that can eat this token
     * and redirect based on this token.
     */
    localStorageSvc.set(
      flowinglyConstants.redirect.LOCAL_STORAGE_KEY,
      JSON.stringify({ type: 'token', value: jwtToken })
    );
  }

  function setPendingRequestAsUrl(redirectUri) {
    localStorageSvc.set(
      flowinglyConstants.redirect.LOCAL_STORAGE_KEY,
      JSON.stringify({ type: 'url', value: { redirectUri } })
    );
  }

  function hasPendingRequest() {
    const jwtString = getPendingRequest();
    return !!jwtString;
  }

  function getPendingRequest() {
    return localStorageSvc.get(flowinglyConstants.redirect.LOCAL_STORAGE_KEY);
  }

  function destroyPendingRequest() {
    return localStorageSvc.remove(
      flowinglyConstants.redirect.LOCAL_STORAGE_KEY
    );
  }

  function readPendingRequest() {
    return $q(function (resolve, reject) {
      const request = JSON.parse(getPendingRequest());
      if (request.type == 'token') {
        request.jwtString = request.value;
        request.value = decodeToken(request.value);
      }
      return resolve(request);
    });
  }

  /**
   * Hits the backend /api/redirection/verify
   * Which checks if the redirect request token is
   * from a valid issuer and to a valid url.
   *
   * @param {string} jwtString jwt in string format
   */
  function validateRequest(request) {
    if (request.type == 'token') {
      return $http.post(`${APP_CONFIG.apiBaseUrl}redirect/verify`, {
        token: request.jwtString
      });
    } else {
      return Promise.resolve({
        data: {
          isValid: true
        }
      });
    }
  }

  function decodeToken(jwtString) {
    return jwtHelper.decodeToken(jwtString);
  }

  function blockDom() {
    unblockDom(); //ensure no duplicates
    blockerElement = $compile('<flowingly-redirect-block />')($rootScope);
    angular.element('body').append(blockerElement);
  }

  function unblockDom() {
    if (blockerElement) {
      blockerElement.remove();
    }
  }

  function redirectToModeler(uri) {
    const queryString = uri.substr(uri.indexOf('?') + 1);
    const pairs = queryString.split('&');
    const keyValuePairs = pairs.map((p) => {
      const keyValue = p.split('=');
      return { key: keyValue[0], value: keyValue[1] };
    });
    const flowModelId = keyValuePairs.find(
      (kvp) => kvp.key === 'flowModelId'
    ).value;
    const openFeedbackPair = keyValuePairs.find(
      (kvp) => kvp.key === flowinglyConstants.redirect.OPEN_FEEDBACK
    );
    const loadPublishRequestSchemaPair = keyValuePairs.find(
      (kvp) =>
        kvp.key === flowinglyConstants.redirect.LOAD_PUBLISH_REQUEST_SCHEMA
    );
    const openFeedback = openFeedbackPair && openFeedbackPair.value === 'true';

    const loadPublishRequestSchema =
      loadPublishRequestSchemaPair &&
      loadPublishRequestSchemaPair.value === 'true';

    const redirectTo = tempModelerUrlService.getModelerUrl(
      flowModelId,
      false,
      openFeedback,
      null,
      loadPublishRequestSchema
    );
    $window.location = redirectTo;
  }

  /*
   * Yes the redirect flow is very complicated because of the tightly coupled
   * architecture. The flow is explained here
   * https://bizflo.atlassian.net/wiki/pages/viewpage.action?pageId=743833731
   */
  function beginRedirectRequest() {
    $window.document.title = 'Redirecting...';
    blockDom();

    /*
     * We need to wrap the actual URL change call in a poll because
     * there is alot of frontend authentication mumbo jumbo things that happen
     * when an authentication is in flight. But we are totally unsure of when
     * or how it happens, we cannot wrap it in a timoeut because authentication
     * might be slow, so the only choice we have is to poll.
     *
     *                                                               - Cassey
     */
    sessionService.onAuthenticated().then(() => {
      if (hasPendingRequest()) {
        executeRedirectRequest();
      }
    });
  }

  /**
   * @returns {Promise}
   */
  function executeRedirectRequest() {
    $window.document.title = 'Redirecting...';
    return readPendingRequest()
      .then((request) => {
        const params = request.value;
        return validateRequest(request).then((response) => {
          const isValid = response.data;

          if (!isValid) {
            $window.location = '/';
          } else if (isModelerRequest(params)) {
            redirectToModeler(params.redirectUri);
          } else {
            $window.location = params.redirectUri;
          }
        });
      })
      .catch((err) => {
        console.error(err);
        $window.location = '/';
      })
      .finally(() => destroyPendingRequest());
  }

  function isModelerRequest({ redirectUri }) {
    return redirectUri.indexOf(flowinglyConstants.redirect.OPEN_MODELER) > -1;
  }

  return {
    setPendingRequestAsJwt,
    setPendingRequestAsUrl,
    hasPendingRequest,
    getPendingRequest,
    destroyPendingRequest,
    validateRequest,
    decodeToken,
    executeRedirectRequest,
    beginRedirectRequest,
    readPendingRequest,
    blockDom,
    unblockDom
  };
}

export type RedirectServiceType = ReturnType<typeof redirectService>;
