import { useService } from '@Client/runner.hooks/useService';
import LeverToggle from '@Client/runner.shared/LeverToggle';
import IFeatureDetail from '@Shared.Angular/@types/core/contracts/config/featureDetail';
import { Guid } from '@Shared.Angular/@types/guid';
import React, { useState } from 'react';

type Props = {
  businessId: Guid;
  feature: IFeatureDetail;
  onFeatureSelect: (featureName: string) => void;
};

const Feature = (props: Props) => {
  const { businessId, feature, onFeatureSelect } = props;
  const displayName = feature.name.split(/(?=[A-Z])/).join(' ');

  const [value, setValue] = useState(feature.value === 'true');
  const [disableToggle, setDisableToggle] = useState(false);

  const businessApiService =
    useService<BusinessApiService>('businessApiService');

  const valueChanged = (newValue: boolean) => {
    setDisableToggle(true);
    businessApiService
      .updateBusinessSetting(businessId, feature.name, `${newValue}`)
      .then((wasSuccess) => {
        if (wasSuccess) {
          setValue(newValue);
        }
        setDisableToggle(false);
      });
  };

  return (
    <div className="feature">
      <div className="feature-details">
        <h5>
          {onFeatureSelect ? (
            <a
              className="feature-name"
              onClick={(event) => onFeatureSelect(feature.name)}
            >
              {displayName}
            </a>
          ) : (
            <div className="feature-name">{displayName}</div>
          )}
        </h5>
        <div className="feature-comments">{feature.comments}</div>
      </div>
      <div className="feature-value">
        <LeverToggle
          value={value}
          showLabels={true}
          isDisabled={disableToggle}
          onValueChanged={valueChanged}
        ></LeverToggle>
      </div>
    </div>
  );
};

export default Feature;
