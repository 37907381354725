/**
 * @ngdoc type
 * @module flowingly.signup
 * @name signupController
 *
 * @description
 */
import * as angular from 'angular';
import IInsertBusinessCommand from '@Shared.Angular/@types/core/contracts/commands/business/insertBusinessCommand';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import ISettingDetail from '@Shared.Angular/@types/core/contracts/config/ISettingDetail';
import SignupService from './signup.service';
import { IStateService } from 'angular-ui-router';
import { SharedAngular } from '@Client/@types/sharedAngular';
import IBusinessTypeDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessTypeDetail';

class SignupController {
  static $inject = [
    'signupService',
    '$state',
    'zxcvbnAdapter',
    'sessionService',
    'tokenService',
    'authService',
    'validationService',
    'fileService',
    'permissionsService',
    'flowinglyConstants',
    'businessApiService'
  ];
  public emailValidationPattern;
  public businessNameValidationPattern = /a-z/i;
  public errorOnSubmit = false;
  public errorMessagesOnSubmit = '';
  public signup = {
    userFirstName: 'Flowingly',
    userLastName: 'Support'
  } as IInsertBusinessCommand;
  public templates = [];
  public businesses: IBusinessDetail[];
  public featureSettings: ISettingDetail[];
  public brandingSettings: ISettingDetail[];
  public timeZones: string[];
  public industries;
  public signupForm;
  public passwordCalculator;
  public loading = true;
  public logoUploaded = (fileId: string) => {
    if (this.signup.logoId) {
      this.fileService.removeFileForId(this.signup.logoId);
    }
    this.signup.logoId = fileId;
  };
  public businessTypes: IBusinessTypeDetail[];

  constructor(
    private signupService: SignupService,
    private $state: IStateService,
    private zxcvbn,
    private sessionService: SharedAngular.SessionService,
    private tokenService: SharedAngular.TokenService,
    private authService: AuthService,
    private validationService: SharedAngular.ValidationService,
    private fileService: SharedAngular.FileService,
    private permissionsService: SharedAngular.PermissionsService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private businessApiService: BusinessApiService
  ) {
    if (
      !this.permissionsService.currentUserHasPermission(
        this.flowinglyConstants.permissions.BUSINESS_SIGNUP
      )
    ) {
      $state.go('app.runner.flowsactive');
      return;
    }

    this.emailValidationPattern =
      this.validationService.getEmailValidationPattern();
    this.industries = signupService.industries;

    const templatePromise = this.getTemplateBusinesses();
    const businessPromise = this.getParentBusinesses();
    const timeZonePromise = this.getTimeZones();
    const businessTypesPromise = this.getBusinessTypes();

    const loadingPromises = [
      templatePromise,
      businessPromise,
      timeZonePromise,
      businessTypesPromise
    ];
    Promise.all(loadingPromises).then(() => (this.loading = false));
  }

  public signupBusiness() {
    if (this.signupForm.$valid) {
      this.errorOnSubmit = false;
      this.errorMessagesOnSubmit = '';
      this.submitNewBusiness();
    }
  }

  public passwordStrengthCalculator() {
    const passScore = {
      0: { description: 'Very Weak', color: 'red', percent: '20%' },
      1: { description: 'Weak', color: 'red', percent: '40%' },
      2: { description: 'Fair', color: 'yellow', percent: '60%' },
      3: { description: 'Good', color: 'green', percent: '80%' },
      4: { description: 'Strong', color: 'green', percent: '100%' }
    };

    const pwd = this.signup.password || '';
    const result = this.zxcvbn(pwd);
    const passResult = passScore[result.score];

    this.passwordCalculator = {
      show: true,
      color: passResult.color,
      strength: passResult.percent,
      strengthText: passResult.description,
      suggestions: result.feedback.suggestions
    };
  }

  private submitNewBusiness() {
    this.loading = true;
    this.featureSettings.forEach((setting) => (setting.value = 'true'));
    this.signup.settings = this.featureSettings
      .filter((setting) => setting.copy)
      .concat(this.brandingSettings.filter((setting) => setting.copy));
    const signupDetails = angular.copy(this.signup);
    const businessType = this.businessTypes.find(
      (bt) => bt.id === this.signup.businessTypeId
    );
    if (businessType.name !== 'Live') {
      signupDetails.displayName = `${signupDetails.displayName} (${businessType.name})`;
    }
    signupDetails.name = null;
    this.signupService.signupBusiness(signupDetails).then(
      async (result) => {
        await this.saveUserAndToken(result);
        this.loading = false;
        this.sessionService.setuserAuthenticated();
        $('.changed-input').removeClass('changed-input');
        this.$state.go('app.login');
      },
      (response) => {
        this.signupForm.$invalid = true;
        this.errorOnSubmit = true;
        this.errorMessagesOnSubmit = response.data?.message;
        this.loading = false;
      }
    );
  }

  private async saveUserAndToken(result) {
    await this.authService.setUserAfterSuccessfulLogin(result.data.user);
    this.tokenService.setToken(result.data.token);
  }

  private getTemplateBusinesses() {
    return this.signupService.getTemplateBusinesses().then((templates) => {
      this.templates = templates.map((template) => {
        return {
          name: template.name,
          id: template.id
        };
      });
    });
  }

  private getParentBusinesses() {
    return this.signupService.getExistingBusinesses().then((businesses) => {
      this.signup.parentId = businesses.find((b) => b.name === 'Flowingly').id;
      this.businesses = businesses;
      this.getParentSettings(this.signup.parentId);
    });
  }

  private getTimeZones() {
    return this.businessApiService.getTimeZones().then((timeZones) => {
      this.timeZones = timeZones.sort();
      this.signup.timeZone = timeZones.find(
        (tz) => tz.indexOf('New Zealand') > -1
      );
    });
  }

  private getBusinessTypes() {
    return this.businessApiService
      .getTypes()
      .then((types) => (this.businessTypes = types));
  }

  public getParentSettings(parentId: string) {
    this.signupService.getSignupSettings(parentId).then((settings) => {
      this.featureSettings = settings
        .filter((setting) => setting.tags.some((tag) => tag.name === 'feature'))
        .sort((a, b) => a.name.localeCompare(b.name));
      this.brandingSettings = settings
        .filter((setting) =>
          setting.tags.some((tag) => tag.name === 'branding')
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      this.featureSettings.forEach((setting) => (setting.copy = true));
      this.brandingSettings.forEach((setting) => (setting.copy = true));
    });
  }

  public getTypeDescription(id: number) {
    return this.businessTypes.find((bt) => bt.id === id)?.description;
  }
}

angular
  .module('flowingly.signup')
  .controller('signupController', SignupController);
