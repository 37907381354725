/**
 * @ngdoc service
 * @name businessApiService
 * @module flowingly.runner.services
 *
 * @description A service for Business related functionality
 *
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import IFeatureDetail from '@Shared.Angular/@types/core/contracts/config/featureDetail';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import IBusinessTypeDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessTypeDetail';
import { Guid } from '@Shared.Angular/@types/guid';
import angular, { IHttpService } from 'angular';

export class BusinessApiService {
  private apiBaseUri: string;
  constructor(
    private $http: IHttpService,
    private APP_CONFIG: SharedAngular.APP_CONFIG
  ) {
    this.apiBaseUri = `${this.APP_CONFIG.apiBaseUrl}business`;
  }

  public getById(businessId: Guid) {
    return this.$http
      .get<IBusinessDetail>(`${this.apiBaseUri}/${businessId}`)
      .then((response) => {
        return response.data;
      });
  }

  public getBusinesses(filters: { displayName?: string; active?: boolean }) {
    return this.$http
      .get<IBusinessDetail[]>(
        `${this.apiBaseUri}?` +
          (filters.displayName ? `&displayName=${filters.displayName}` : '') +
          (filters.active ? `active=${filters.active}` : '')
      )
      .then((response) => {
        return response.data;
      });
  }

  public updateBusiness(businessDetail: IBusinessDetail) {
    return this.$http
      .put<IBusinessDetail>(
        `${this.apiBaseUri}/${businessDetail.id}`,
        businessDetail
      )
      .then((response) => {
        return response.data;
      });
  }

  public getTimeZones() {
    return this.$http
      .get<string[]>(`${this.apiBaseUri}/timezones`, { cache: true })
      .then((response) => {
        return response.data;
      });
  }

  public getTypes() {
    return this.$http
      .get<IBusinessTypeDetail[]>(`${this.apiBaseUri}/types`, { cache: true })
      .then((response) => {
        return response.data;
      });
  }

  public getFeatures(businessId: Guid) {
    return this.$http
      .get<IFeatureDetail[]>(
        `${this.apiBaseUri}/${businessId}/settings/features`
      )
      .then((response) => {
        return response.data;
      });
  }

  public updateBusinessSetting(businessId: Guid, name: string, value: string) {
    const uriPath = `/${businessId}/settings?settingName=${encodeURIComponent(
      name
    )}`;
    return this.$http
      .put(this.apiBaseUri + uriPath, JSON.stringify(value))
      .then(
        (response) => {
          return response.status === 200;
        },
        () => {
          return false;
        }
      );
  }
}

BusinessApiService.$inject = ['$http', 'APP_CONFIG'];

angular
  .module('flowingly.runner.services')
  .service('businessApiService', BusinessApiService);

export type BusinessApiServiceType = InstanceType<typeof BusinessApiService>;
