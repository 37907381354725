import React, { useState } from 'react';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import BusinessFeaturesView from './FeatureList/FeatureList';
import FeatureAdminView from './FeatureAdminView/FeatureAdminView';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { Guid } from '@Shared.Angular/@types/guid';
import SelectBusiness from '@Client/runner.shared/SelectBusiness';

const SetupFeatures = () => {
  const sessionService =
    useService<SharedAngular.SessionService>('sessionService');
  const permissionsService =
    useService<SharedAngular.PermissionsService>('permissionsService');

  const [businessId, setBusinessId] = useState(sessionService.getBusinessId());
  const [targetFeatureName, setTargetFeatureName] = useState('');
  const isAdmin = permissionsService.currentUserHasPermission(
    FlowinglyPermissions.SETUP_FEATURE_ADMIN
  );

  const onBusinessChange = (newBusinessId: Guid) => {
    setBusinessId(newBusinessId);
  };

  return (
    <div id="setup-features">
      <link
        href="https://kendo.cdn.telerik.com/themes/7.0.1/material/material-main.css"
        rel="stylesheet"
        type="text/css"
      />
      <div id="left-pane">
        {isAdmin && (
          <div id="features-select-business-container">
            <SelectBusiness
              onBusinessSelect={onBusinessChange}
              selectedBusinessId={businessId}
              showClearButton={true}
            ></SelectBusiness>
          </div>
        )}
        <BusinessFeaturesView
          businessId={businessId}
          onFeatureSelect={isAdmin ? setTargetFeatureName : null}
        ></BusinessFeaturesView>
      </div>
      {isAdmin && (
        <div id="right-pane">
          <FeatureAdminView featureName={targetFeatureName}></FeatureAdminView>
        </div>
      )}
    </div>
  );
};
export default SetupFeatures;
