/**
 * @ngdoc service
 * @name setupDeleteApiService
 * @module flowingly.runner.setup
 *
 * @description A service responsible solely for deleting setup items (users, teams, categories)
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 * i.e. we return response.data.DataModel so the consumer can work with the data directly
 *
 */
import { SharedAngular } from '@Client/@types/sharedAngular';
import { Guid } from '@Shared.Angular/@types/guid';
import IUserAssignedStepTask from '@Shared.Angular/@types/core/contracts/queryModel/actor/userAssignedStepTask';
import { DeleteEntityTypes } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { IHttpService } from 'angular';

interface IDeleteUser {
  email: string;
  id: Guid;
  fullName: string;
  isManager: boolean;
}
class SetupDeleteApiService {
  static $inject = [
    '$http',
    '$q',
    'APP_CONFIG',
    'sessionService',
    'notificationService',
    'dialogService',
    'userApiService'
  ];

  constructor(
    private $http: IHttpService,
    private $q,
    private APP_CONFIG,
    private sessionService: SharedAngular.SessionService,
    private notificationService,
    private dialogService,
    private userApiService
  ) {}
  //////////// Public API Methods

  public getTeams() {
    return this.$http
      .get(this.APP_CONFIG.apiBaseUrl + 'teams?includeDetails=false')
      .then((res) => {
        return res.data.dataModel;
      });
  }

  public getWarnings(entityType, itemId) {
    if (entityType === DeleteEntityTypes.categoryEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'categories/deletewarnings?categoryId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (entityType === DeleteEntityTypes.teamEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'teams/deletewarnings?teamId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (entityType === DeleteEntityTypes.userEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'users/deletewarnings?userId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (entityType === DeleteEntityTypes.flowModelEntityType) {
      const businessName = this.sessionService.getBusinessName();
      return this.$q.when({
        warnings: [
          `The flow model will be removed from ${businessName}. This cannot be undone!`
        ]
      });
    } else if (entityType === DeleteEntityTypes.databaseEntityType) {
      return this.$q.when({
        warnings: [
          'Make sure there are no Workflows using this database, ' +
            'if there are, deleting it might cause them to stop functioning.'
        ]
      });
    } else if (entityType === DeleteEntityTypes.roleEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'roles/deletewarnings?roleId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else {
      console.error('Unknown entity type');
    }
  }

  public getUserAssignedStepTasks(userId) {
    return this.$http.get<IUserStepTasks>(
      this.APP_CONFIG.apiBaseUrl + 'users/userStepTasks/' + userId,
      {
        noSpinner: false
      }
    );
  }

  public tryDeleteUser(
    user: IDeleteUser,
    currentUserEmail,
    scope,
    onUsersUpdated
  ) {
    if (user.email === currentUserEmail) {
      this.notificationService.showErrorToast(
        'Sorry, You cannot delete yourself.'
      );
      return;
    }
    this.getUserAssignedStepTasks(user.id).then((response) => {
      if (
        response.status !== 200 ||
        !response.data ||
        !response.data.stepTasks
      ) {
        const message =
          response.message ||
          'Error occurred while getting user assigned step tasks';
        this.notificationService.showErrorToast(message);
        return;
      }

      if (response.data.stepTasks.length === 0) {
        this.showDeleteUserDialog(scope, user, onUsersUpdated);
        return;
      }

      this.showStepTasksWarningDialog(response.data, scope);
    });
  }

  public showDeleteUserDialog(scope, user, onUsersUpdated) {
    this.dialogService
      .showDialog({
        template:
          'Client/runner.setup/runner.setup.delete/runner.setup.delete.dialog.tmpl.html',
        controller: 'setupDeleteDialogController',
        appendClassName: 'ngdialog-normal',
        scope: scope,
        data: {
          itemToDelete: user,
          entityType: DeleteEntityTypes.userEntityType
        }
      })
      .then((response) => {
        if (this.dialogService.isCloseModalWithCancelAction(response)) {
          return;
        }
        //here we will call the service to delete
        this.userApiService
          .deleteUser(
            user.id,
            response.actor,
            response.manager,
            response.customdatabaseactor,
            response.categoryApprover
          )
          .then(() => {
            this.notificationService.showSuccessToast(
              `${user.fullName} successfully Deleted.`,
              1000
            );
            //raise an 'event' to notify the parent
            if (onUsersUpdated) {
              onUsersUpdated();
            }
          });
      });
  }

  public showStepTasksWarningDialog(tasksObject, scope) {
    this.dialogService.showDialog({
      template:
        'Client/runner.setup/runner.setup.delete/runner.setup.users.stepTasks.dialog.tmpl.html',
      controller: 'setupUserStepTaskDialogController',
      appendClassName: 'ngdialog-normal',
      scope: scope,
      data: {
        tasksObject: tasksObject
      }
    });
  }
}

angular
  .module('flowingly.runner.setup')
  .factory('setupDeleteApiService', SetupDeleteApiService);

export interface IUserStepTasks {
  userName: string;
  stepTasks: IUserAssignedStepTask[];
}
