import React, { useRef, useState } from 'react';

interface SearchProps {
  value: string;
  placeholder: string;
  onValueChange: (value: string) => void;
}

const Search: React.FC<SearchProps> = ({
  value,
  onValueChange,
  placeholder
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onValueChange(value);
    setInputValue(value);
  };

  const handleClearSearch = () => {
    onValueChange('');
    setInputValue('');
  };

  return (
    <div className="search-container">
      <div className="search-icon">
        <i className="fa-light fa-magnifying-glass"></i>
      </div>
      <input
        ref={searchInputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
      {inputValue !== '' && (
        <i
          className="fa-light fa-times search-clear"
          onClick={handleClearSearch}
          title="Clear"
        ></i>
      )}
    </div>
  );
};

export default Search;
