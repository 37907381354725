import { SharedAngular } from '@Client/@types/sharedAngular';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular from 'angular';

export default class RunnerSetupTabsComponentController {
  static $inject = ['APP_CONFIG', 'permissionsService'];

  private isCategoryPageAccessable = false;
  private isDatabaseFeatureEnabled = false;
  private isDatabasePageAccessable = false;
  private isRolesPageAccessable = false;
  private isTeamPageAccessable = false;
  private isUserPageAccessable = false;
  private isFeaturePageAccessable = false;

  constructor(
    APP_CONFIG: SharedAngular.APP_CONFIG,
    permissionsService: SharedAngular.PermissionsService
  ) {
    this.isUserPageAccessable = permissionsService.currentUserHasPermission(
      FlowinglyPermissions.SETUP_USER_ACCESS
    );
    this.isTeamPageAccessable = permissionsService.currentUserHasPermission(
      FlowinglyPermissions.SETUP_TEAM_ACCESS
    );
    this.isDatabasePageAccessable = permissionsService.currentUserHasPermission(
      FlowinglyPermissions.SETUP_DATABASE_ACCESS
    );
    this.isCategoryPageAccessable = permissionsService.currentUserHasPermission(
      FlowinglyPermissions.SETUP_CATEGORY_ACCESS
    );

    this.isDatabaseFeatureEnabled = APP_CONFIG.enableDatabaseFeature;

    this.isRolesPageAccessable =
      permissionsService.currentUserHasPermission(
        FlowinglyPermissions.SETUP_ROLE_ACCESS
      ) && APP_CONFIG.enableSetupRoles;

    this.isFeaturePageAccessable =
      permissionsService.currentUserHasAnyOfPermissions([
        FlowinglyPermissions.SETUP_FEATURE_ADMIN,
        FlowinglyPermissions.SETUP_FEATURE_UPDATE
      ]) && APP_CONFIG.enableSetupFeatures;
  }
}

export class RunnerSetupTabsComponent implements angular.IComponentOptions {
  public templateUrl =
    'Client/runner.setup/runner.setup.tabs.component.tmpl.html';

  controller = RunnerSetupTabsComponentController;
}

angular
  .module('flowingly.runner.setup')
  .component('runnerSetupTabs', new RunnerSetupTabsComponent());
