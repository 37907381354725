import { useService } from '@Client/runner.hooks/useService';
import LeverToggle from '@Client/runner.shared/LeverToggle';
import Search from '@Client/runner.shared/Search';
import ISettingDetail from '@Shared.Angular/@types/core/contracts/config/ISettingDetail';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import { Guid } from '@Shared.Angular/@types/guid';
import SetupApiService from '@Shared.Angular/flowingly.services/setup.api.service';
import React, { useEffect, useState } from 'react';

type Props = {
  featureName: string;
};

type BusinessState = {
  overrideValue: string;
  pendingRequest: boolean;
};

const FeatureAdminView = (props: Props) => {
  const { featureName } = props;
  const [feature, setFeature] = useState<ISettingDetail>();
  const [overridesByBusinessId, setoverridesByBusinessId] = useState<{
    [key: string]: BusinessState;
  }>({});
  const [businesses, setBusinesses] = useState<IBusinessDetail[]>([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState<
    IBusinessDetail[]
  >([]);

  const setupApiService = useService<SetupApiService>('setupApiService');

  useEffect(() => {
    featureName &&
      setupApiService.getFeature(featureName).then((featureDetail) => {
        const overrides = {};
        featureDetail.overrides.forEach((o) => {
          o.value = o.value.toLowerCase();
          overrides[o.businessId] = {
            overrideValue: o.value,
            pendingRequest: false
          };
        });
        setFeature(featureDetail);
        setoverridesByBusinessId(overrides);
      });
  }, [featureName]);

  const businessApiService =
    useService<BusinessApiService>('businessApiService');

  useEffect(() => {
    businessApiService
      .getBusinesses({ active: true })
      .then((businessDetails) => {
        businessDetails.sort((a, b) => a.name.localeCompare(b.name));
        setBusinesses(businessDetails);
        setFilteredBusinesses(businessDetails);
      });
  }, []);

  const onSearchValueChange = (value: string) => {
    if (value === '') {
      setFilteredBusinesses(businesses);
      return;
    }
    value = value?.toLowerCase();
    setFilteredBusinesses(
      businesses.filter((b) => b.name.toLowerCase().includes(value))
    );
  };

  const onBusinessOverrideChange = (newValue: boolean, businessId: Guid) => {
    if (!overridesByBusinessId[businessId]) {
      overridesByBusinessId[businessId] = {
        overrideValue: newValue.toString(),
        pendingRequest: false
      };
    }
    overridesByBusinessId[businessId].pendingRequest = true;
    setoverridesByBusinessId({ ...overridesByBusinessId });
    businessApiService
      .updateBusinessSetting(businessId, feature.name, newValue.toString())
      .then((wasSuccess) => {
        overridesByBusinessId[businessId].pendingRequest = false;
        if (wasSuccess) {
          overridesByBusinessId[businessId].overrideValue = newValue.toString();
        }
        setoverridesByBusinessId({ ...overridesByBusinessId });
      });
  };

  return (
    feature && (
      <div id="feature-admin-view">
        <h5>
          {feature.name} defaults to <em>{feature.value}</em>
        </h5>
        <p>{feature.comments}</p>
        <Search
          value=""
          placeholder="Filter business names"
          onValueChange={onSearchValueChange}
        ></Search>
        <div>
          {filteredBusinesses.map((b) => (
            <div className="feature-admin-business" key={b.id}>
              <LeverToggle
                value={
                  (overridesByBusinessId[b.id]?.overrideValue ||
                    feature.value) === 'true'
                }
                showLabels={true}
                isDisabled={
                  overridesByBusinessId[b.id]?.pendingRequest || false
                }
                onValueChanged={(newValue: boolean) =>
                  onBusinessOverrideChange(newValue, b.id)
                }
              ></LeverToggle>
              <div className="feature-admin-business-name" title={b.name}>
                {b.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default FeatureAdminView;
