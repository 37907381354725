import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { SharedAngular } from '../../@types/sharedAngular';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';

angular.module('flowingly.runner.setup').config(config);
config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('app.runner.setup.features', {
    url: '/features',
    params: { flowId: null, title: 'Setup - Features' },
    views: {
      childcontent: {
        controllerAs: '$ctrl',
        controller: [
          'permissionsService',
          'setupApiService',
          'flowinglyConstants',
          '$state',
          function (
            permissionsService: SharedAngular.PermissionsService,
            $state: IStateService
          ) {
            if (
              !permissionsService.currentUserHasAnyOfPermissions([
                FlowinglyPermissions.SETUP_FEATURE_UPDATE,
                FlowinglyPermissions.SETUP_FEATURE_ADMIN
              ])
            ) {
              $state.go('app.runner.flowsactive');
            }
          }
        ],
        templateUrl:
          'Client/runner.setup/runner.setup.features/runner.setup.features.tmpl.html'
      }
    }
  });
}
