import React, { useState, useEffect } from 'react';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useService } from '@Client/runner.hooks/useService';
import { Guid } from '@Shared.Angular/@types/guid';
import { BusinessApiService } from '@Client/runner.services/business.api.service';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';

type Props = {
  selectedBusinessId?: Guid;
  onBusinessSelect?: (businessId: string) => void;
  showClearButton: boolean;
};

const SelectBusiness = (props: Props) => {
  const { selectedBusinessId, onBusinessSelect, showClearButton } = props;

  const [businesses, setBusinesses] = useState<IBusinessDetail[]>([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState<
    IBusinessDetail[]
  >([]);
  const [selectedBusiness, setSelectedBusiness] = useState<IBusinessDetail>();

  const businessApiService =
    useService<BusinessApiService>('businessApiService');

  useEffect(() => {
    businessApiService.getBusinesses({ active: true }).then((results) => {
      results.sort((a, b) => a.name.localeCompare(b.name));
      setBusinesses(results);
      setFilteredBusinesses(results);
      if (selectedBusinessId) {
        const business = results.find(
          (b) => b.id === selectedBusinessId.toLowerCase()
        );
        setSelectedBusiness(business);
      }
    });
  }, []);

  const handleSelectionChange = (event: ComboBoxChangeEvent) => {
    const selectedBusiness = event.value as IBusinessDetail;
    setSelectedBusiness(selectedBusiness);
    onBusinessSelect?.(selectedBusiness?.id);
  };

  const handleFilterChange = (event) => {
    if (!event.filter.value) {
      setFilteredBusinesses([...businesses]);
      return;
    }
    const searchTerm = event.filter.value.toLowerCase();
    setFilteredBusinesses(
      businesses.filter((b) => b.name.toLowerCase().includes(searchTerm))
    );
  };

  return (
    <ComboBox
      className="select-business-combobox"
      data={filteredBusinesses}
      textField="name"
      dataItemKey="id"
      placeholder="Select Business"
      value={selectedBusiness}
      onChange={handleSelectionChange}
      filterable={true}
      onFilterChange={handleFilterChange}
      clearButton={showClearButton}
    />
  );
};

export default SelectBusiness;
