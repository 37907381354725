import { SharedAngular } from '@Client/@types/sharedAngular';
import { BusinessDomainConnectionTypes } from '@Shared.Angular/@types/core/contracts/constants/businessDomainConnectionTypes';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import IBusinessDomainConnectionDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDomainConnectionDetail';
import IBusinessTypeDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessTypeDetail';
import { EmptyGuid, Guid } from '@Shared.Angular/@types/guid';
import { IConfirmDialogOptions } from '@Shared.Angular/flowingly.services/dialog.service';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { IScope } from 'angular';

export const BUSINESS_UPDATED_EVENT = 'BUSINESS_UPDATED';
export const BUSINESS_TERMINATED_POSTFIX = ' (Terminated)';

class MaintenanceBusinessController {
  static $inject = [
    'maintenanceService',
    '$scope',
    'notificationService',
    'dialogService',
    'businessApiService',
    'permissionsService'
  ];

  public changeRequestId: string;
  public business: IBusinessDetail;
  public businessName: string;
  public allBusinesses: IBusinessDetail[];
  public timeZones: string[];
  public businessTypes: IBusinessTypeDetail[];
  public loading = false;
  public hasActionsPermission = false;

  public entityTypes = [
    'Flow',
    'FlowModel',
    'FlowModelSchema',
    'Step',
    'User',
    'Team',
    'Business'
  ];
  public entityType = this.entityTypes[0];
  public entityId: string;
  public entityBusinessId: Guid;
  public entityBusinessName: string;
  public entityName: string;
  public domainConnectionsAreDirty = false;

  constructor(
    private maintenanceService: MaintenanceService,
    private $scope: IScope,
    private notificationService: SharedAngular.NotificationService,
    private dialogService: SharedAngular.DialogService,
    private businessApiService: BusinessApiService,
    private permissionsService: SharedAngular.PermissionsService
  ) {
    $scope.$parent.$watch(
      '$ctrl.selectedBusiness',
      (selectedBusiness: IBusinessDetail) => {
        this.businessName = selectedBusiness?.name;
        this.business = angular.copy(selectedBusiness);
        this.domainConnectionsAreDirty = false;
      }
    );
    $scope.$parent.$watch(
      '$ctrl.businesses',
      (allBusinesses: IBusinessDetail[]) => {
        this.allBusinesses = allBusinesses;
      }
    );
    this.businessApiService.getTimeZones().then((timeZones) => {
      this.timeZones = timeZones.sort();
    });
    this.businessApiService.getTypes().then((types) => {
      this.businessTypes = types;
    });
    this.hasActionsPermission =
      this.permissionsService.currentUserHasAnyOfPermissions([
        FlowinglyPermissions.ADMIN_ACTIONS,
        FlowinglyPermissions.ADMIN_ACTIONS_BUSINESS
      ]);
  }

  public updateBusiness(business: IBusinessDetail) {
    this.loading = true;
    const dialogOptions: IConfirmDialogOptions = {
      title: `Update '${this.businessName}'?`,
      actionButtonClass: 'blue',
      disabledSeconds: 2
    };
    this.dialogService
      .showConfirmDialog(this.$scope, dialogOptions)
      .then(() => {
        return this.businessApiService.updateBusiness(business);
      })
      .then((updatedBusiness) => {
        this.business = updatedBusiness;
        const businessIndex = this.allBusinesses.findIndex(
          (b) => b.id === updatedBusiness.id
        );
        this.allBusinesses.splice(businessIndex, 1, updatedBusiness);
        this.$scope.$emit(BUSINESS_UPDATED_EVENT, updatedBusiness);
        this.loading = false;
        this.notificationService.showSuccessToast(
          `'${this.businessName}' updated.`
        );
      })
      .catch(() => {
        this.loading = false;
      });
  }

  public terminateBusiness(businessId: Guid) {
    this.loading = true;
    const dialogOptions: IConfirmDialogOptions = {
      title: `Terminate '${this.businessName}'?`,
      message:
        `Terminating '${this.businessName}' will de-activate all its Users, ` +
        'delete its Flow Models, ' +
        'and finalise all its Flows. There is no easy way to undo this. ARE YOU SURE? ' +
        `${this.businessName}?`,
      confirmationWord: 'TERMINATE',
      actionButtonClass: 'red',
      disabledSeconds: 10
    };
    this.dialogService
      .showConfirmDialog(this.$scope, dialogOptions)
      .then(() => {
        return this.maintenanceService.terminateBusiness(
          businessId,
          this.changeRequestId
        );
      })
      .then(() => {
        this.loading = false;
        this.notificationService.showSuccessToast(
          `'${this.businessName}' terminated.`
        );
        this.business.active = false;
        this.business.name += BUSINESS_TERMINATED_POSTFIX;
        this.businessName = this.business.name;
        const businessIndex = this.allBusinesses.findIndex(
          (b) => b.id === businessId
        );
        this.allBusinesses.splice(businessIndex, 1, this.business);
        this.$scope.$emit(BUSINESS_UPDATED_EVENT, this.business);
      })
      .catch(() => {
        this.loading = false;
      });
  }

  public unterminateBusiness(businessId: Guid) {
    this.loading = true;
    const dialogOptions: IConfirmDialogOptions = {
      title: `Unterminate '${this.businessName}'?`,
      actionButtonClass: 'orange',
      disabledSeconds: 1
    };
    this.dialogService
      .showConfirmDialog(this.$scope, dialogOptions)
      .then(() => {
        return this.maintenanceService.unterminateBusiness(
          businessId,
          this.changeRequestId
        );
      })
      .then(() => {
        this.loading = false;
        this.notificationService.showSuccessToast(
          `'${this.businessName}' unterminated.`
        );
        this.business.active = true;
        this.business.name = this.business.name.replace(
          BUSINESS_TERMINATED_POSTFIX,
          ''
        );
        this.businessName = this.business.name;
        const businessIndex = this.allBusinesses.findIndex(
          (b) => b.id === businessId
        );
        this.allBusinesses.splice(businessIndex, 1, this.business);
        this.$scope.$emit(BUSINESS_UPDATED_EVENT, this.business);
      })
      .catch(() => {
        this.loading = false;
      });
  }

  public findBusinessOfEntity() {
    if (!this.entityType || !this.entityId) {
      this.entityBusinessId = '';
      this.entityBusinessName = '';
      return;
    }
    this.maintenanceService
      .findBusinessOfEntity(this.entityType, this.entityId)
      .then((result) => {
        this.entityBusinessId = result.businessId;
        this.entityBusinessName = result.businessName;
        this.entityName = result.entityName;
      })
      .catch(() => {
        this.entityBusinessId = '';
        this.entityBusinessName = '';
        this.entityName = '';
      });
  }

  public addDomainConnection() {
    this.business.domainConnections.push({
      id: EmptyGuid,
      businessId: this.business.id,
      domain: '',
      type: BusinessDomainConnectionTypes.Password
    });
    this.domainConnectionsAreDirty = true;
  }

  public removeDomainConnection(connection: IBusinessDomainConnectionDetail) {
    this.business.domainConnections = this.business.domainConnections.filter(
      (bdc) => bdc !== connection
    );
    this.domainConnectionsAreDirty = true;
  }

  public getBusinessTypeDescription(id: number) {
    return this.businessTypes?.find((bt) => bt.id === id)?.description;
  }
}

angular
  .module('flowingly.runner.maintenance')
  .controller('maintenanceBusinessController', MaintenanceBusinessController);
