import React from 'react';

type Props = {
  value: boolean;
  showLabels: boolean;
  isDisabled: boolean;
  onValueChanged: (newValue: boolean) => void;
};

const LeverToggle = (props: Props) => {
  const { value, showLabels, onValueChanged, isDisabled } = props;

  return (
    <div className="switch lever-toggle">
      <span
        className={
          !showLabels ? 'invisible' : value === true ? 'transparent' : ''
        }
      >
        Off
      </span>
      <label>
        <input
          type="checkbox"
          className="clickable"
          checked={value}
          disabled={isDisabled}
          onChange={(event) => onValueChanged(event.target.checked)}
        />
        <span className="lever"></span>
      </label>
      <span
        className={
          !showLabels ? 'invisible' : value === false ? 'transparent' : ''
        }
      >
        On{isDisabled}
      </span>
    </div>
  );
};

export default LeverToggle;
